import http from "@/utils/services/http"

export const listValidadeDocumentos = (limit, page, filtros) => {
    let url = '/api/validade-documento?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findValidadeDocumento = (id) => {
    let url = `/api/validade-documento/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const previewValidadeDocumento = (id, data) => {
    let url = `/api/validade-documento/${id}/preview`
    return http.post(url, {
        document: data
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newValidadeDocumento = (data) => {
    let url = `/api/validade-documento`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateValidadeDocumento = (documento, method = 'PUT') => {
    let url = `/api/validade-documento/${documento.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, documento)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteValidadeDocumento = (id) => {
    let url = `/api/validade-documento/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const uploadDocumento = (id, data) => {
    let url = `/api/validade-documento/${id}/arquivo`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadDocumento = (documento, arquivo, inline = false) => {
    let url = `/api/validade-documento/${documento}/arquivos/${arquivo}/download`
    if (inline) {
        url = url + '?inline=true'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
